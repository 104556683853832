import React, { useEffect, useContext, useState } from "react";
import { SliceZone } from "@prismicio/react";

import NewPasswordForm from "../components/auth/NewPasswordForm";
import * as Slices from "../slices";
import { ModalContext } from "../context/ModalContext";
import { createClient } from "../prismic";
import { getParameterByName } from "../utils/helpers";

const Home = ({ page, slices }): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const { handleModal } = useContext(ModalContext);

  const data = page.data;

  useEffect(() => {
    const type = getParameterByName("type");
    if (type === "recovery" && !modalOpen) {
      handleModal({
        content: <NewPasswordForm />,
      });
      setModalOpen(true);
    }
  }, [handleModal, modalOpen]);

  useEffect(() => {});

  return (
    <>
      <SliceZone
        sliceProps={{
          pageData: data,
          globalData: page,
        }}
        {...data}
        components={Slices.components}
      />
    </>
  );
};

// Fetch content from prismic
export const getStaticProps = async ({ params, previewData }) => {
  const client = createClient();
  const previewRef = previewData ? previewData.ref : null;

  let refOption = previewRef ? { ref: previewRef } : {};

  const page = await client.getSingle("home", refOption);

  return {
    props: { page, previewRef },
  };
};

export default Home;
